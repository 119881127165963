.contain-error{
    position: relative;
    small{
        position: absolute;
        top: -20px;
        right: 0;
    }
}
.text-validate{
    position: absolute;
}
.input-group{
    .text-validate{
        left: 0;
        bottom: -15px;
    }
   
}
