.file-input-wrapper {
    width: 30px;
    height: 30px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    cursor: pointer;
    [class^="icon-"]{
        font-size: 14px;
    }


}
.file-input-wrapper > input[type="file"] {
    font-size: 200px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

}
.file-input-wrapper > .btn-file-input {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor: pointer;
}
 img{
    object-fit: cover;
 }
