.navigation > li.active > span.ref-a,
.navigation > li.active > span.ref-a:hover,
.navigation > li.active > span.ref-a:focus {
    background-color: #ff9800 !important;
    color: #fff;
}
.sc-module-menu {
    padding: 0 !important;
    a {
        display: block;
        padding: 20px;
    }
}

.navigation li {
    span.ref-a {
        display: block;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        padding: 12px 0;
        cursor: pointer;
    }
}
.sidebar-xs .sidebar-main .navigation > li > span.ref-a > span {
    background-color: #00b8b8;
    border: 1px solid #00b8b8;
}
.sidebar-xs .sidebar-main .navigation > li:hover > span.ref-a > span {
    display: block;
}

.sidebar-xs .sidebar-main .navigation > li:hover:not(.active) > span.ref-a {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
}
.w-32 {
    width: 32px;
}
.fade {
    &.show {
        opacity: 1;
    }
}
.menu-popover {
    .popover {
        &.fade {
            display: none !important;
            &.in {
                display: block !important;
            }
        }
    }
    .popover-body {
        padding: 15px;
    }
}
.sc-module-title {
    margin: 10px 0;
}

.navbar-brand {
    cursor: pointer;
    padding: 8px 13px 8px 10px;
    // background-color: #0b2239;
    img {
        height: 32px;
        margin-top: 0;
    }
}

@media (min-width: 769px) {
    .sidebar-xs .sidebar-main .navigation > li > span.ref-a > span {
        display: none;
        position: absolute;
        top: 0;
        right: -260px;
        padding: 18px 20px;
        width: 260px;
        text-align: left;
        color: #fff;
        cursor: pointer;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        font-weight: bold;
    }
}

@media (max-width: 768px) {
    .sc-module-menu {
        a {
            display: block;
            padding: 10px;
        }
    }
    .navigation li span.ref-a {
        text-align: left;
        padding-left: 20px;
    }
    .sidebar-xs .sidebar-main .navigation > li > span.ref-a > span {
        background: transparent;
        border: none;
        margin-left: 15px;
    }
    .sidebar-xs .sidebar-main .navigation > li:hover > span.ref-a > span {
        display: inline-block;
    }
}
