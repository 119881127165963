.btn-group {
    .wrap-contain-tooltip {
        display: inline-block;
        margin: 0 1px;
        button {
            border-radius: 0;
        }

        .ant-btn-icon-only {
            width: 42px;
            height: 36px;

            font-size: 14px;

            .anticon {
                vertical-align: middle;
                font-size: 1.4em;
            }
        }
    }
    .wrap-contain-tooltip:first-child {
        margin-left: 0;
        button {
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }
    }
    .wrap-contain-tooltip:last-child {
        margin-right: 0;
        button {
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
}
