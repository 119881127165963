legend {
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    padding: 0 150px;
}

.ant-tabs-nav-container {
    font-size: 17px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    height: 40px;
    color: #1890ff;
    background: #fff;
    border-color: #1890ff;
    border-bottom: 1px solid #fff;
    border-top-width: 5px;
}
.school-setting{
    input{
        font-weight: 600;
    }
}