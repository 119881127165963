.form-group{
    .contain-error{
        small{
            position: absolute;
            bottom: -16px;
            color: #f44336 !important;
            left: 0;
        }
    }
}
    
.disable-modified{
    color: #333 !important;
}

