
.my-nav{
    .navbar-mobile{
        display: block;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        padding: 12px 0;
        cursor: pointer;
    }
    .wrap-avatar{
        height: 30px;
        width: 30px;
        position: absolute;
        overflow: hidden;
        border-radius: 50%;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translate(0,-50%);
    }
    .dropdown-user .dropdown-toggle{
       padding-left: 40px;
    }
}
