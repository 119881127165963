@keyframes ldio-hpnteioc1h {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
  }
  .ldio-hpnteioc1h div { box-sizing: border-box!important }
  .ldio-hpnteioc1h > div {
    position: absolute;
    width: 81.09px;
    height: 81.09px;
    top: 35.955px;
    left: 35.955px;
    border-radius: 50%;
    border: 4.59px solid #000;
    border-color: #1e70b8 transparent #1e70b8 transparent;
    animation: ldio-hpnteioc1h 0.7462686567164178s linear infinite;
  }
  
  .ldio-hpnteioc1h > div:nth-child(2), .ldio-hpnteioc1h > div:nth-child(4) {
    width: 68.85px;
    height: 68.85px;
    top: 42.075px;
    left: 42.075px;
    animation: ldio-hpnteioc1h 0.7462686567164178s linear infinite reverse;
  }
  .ldio-hpnteioc1h > div:nth-child(2) {
    border-color: transparent #f7941d transparent #f7941d
  }
  .ldio-hpnteioc1h > div:nth-child(3) { border-color: transparent }
  .ldio-hpnteioc1h > div:nth-child(3) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-hpnteioc1h > div:nth-child(3) div:before, .ldio-hpnteioc1h > div:nth-child(3) div:after { 
    content: "";
    display: block;
    position: absolute;
    width: 4.59px;
    height: 4.59px;
    top: -4.59px;
    left: 33.660000000000004px;
    background: #1e70b8;
    border-radius: 50%;
    box-shadow: 0 76.5px 0 0 #1e70b8;
  }
  .ldio-hpnteioc1h > div:nth-child(3) div:after {
    left: -4.59px;
    top: 33.660000000000004px;
    box-shadow: 76.5px 0 0 0 #1e70b8;
  }
  
  .ldio-hpnteioc1h > div:nth-child(4) { border-color: transparent; }
  .ldio-hpnteioc1h > div:nth-child(4) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-hpnteioc1h > div:nth-child(4) div:before, .ldio-hpnteioc1h > div:nth-child(4) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 4.59px;
    height: 4.59px;
    top: -4.59px;
    left: 27.54px;
    background: #f7941d;
    border-radius: 50%;
    box-shadow: 0 64.26px 0 0 #f7941d;
  }
  .ldio-hpnteioc1h > div:nth-child(4) div:after {
    left: -4.59px;
    top: 27.54px;
    box-shadow: 64.26px 0 0 0 #f7941d;
  }
  .loadingio-spinner-double-ring-j8ipsq9bil {
    width: 153px;
    height: 153px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
  }
  .ldio-hpnteioc1h {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-hpnteioc1h div { box-sizing: content-box; }



.bg-spinner{
    z-index: 999999;
    position:  fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #fff, $alpha: 0.9);
    .loadingio-spinner-double-ring-j8ipsq9bil{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

